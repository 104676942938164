import React from 'react';

import styled from '@emotion/styled';

import { IProduct } from '../../../../types';
import { Button, ButtonVariants } from '../../../Button';
import HTMLReactParser from 'html-react-parser';
import { imgSrcByEnv } from '../../../../../config/imgSrcByEnv';
import { useLocalization } from '../../../../hooks/useLocalization';

export const CartItem = ({
  urlParams,
  item,
  onRemove,
  onPriceButtonClick,
  onClick,
}: {
  urlParams?: any;
  item: IProduct;
  onRemove: any;
  onPriceButtonClick: any;
  onClick: any;
  }) => {
  const { translation } = useLocalization();
  const price: number = item.Price ? item.Price : item.RegularPrice || 0;
  let totalAmount = ''
  if (item.PromoAmount && item.amount >= item.PromoAmount) {
    totalAmount = ((Math.floor(item.amount / item.PromoAmount) * item.PromoCount * price) + ((item.amount % item.PromoAmount) * price)).toFixed(2);
  } else {
    totalAmount = (item.amount * price).toFixed(2);
  }

  return (
    <CartItemStyled onClick={onClick}>
      <div className="image-column">
        {item.Gallery && item.Gallery[0] ? (
          <picture>
            <img
              src={`${imgSrcByEnv()}${item.Gallery[0].image.fullpath}`}
              alt="product"
            />
          </picture>
        ) : (
          <img src="http://placehold.jp/150x300.png" alt="product" />
        )}
        <u onClick={onRemove}>{translation?.getLocalization?.Remove || "Remove"}</u>
      </div>
      <div className="description-column">
        <p>{HTMLReactParser(item.Name)}</p>
        <p>{translation?.getLocalization?.AvailableForPickup || "Available for pickup"}</p>
        <u>{translation?.getLocalization?.SaveForLater || "Save for later"}</u>
      </div>
      <div className="price-column">
        <p>${totalAmount}</p>
        <div className="price-button">
          <Button
            variant={ButtonVariants.addTransparent}
            content={{ product: item }}
            onClick={onPriceButtonClick}
            url={urlParams}
          />
        </div>
      </div>
    </CartItemStyled>
  );
};

const CartItemStyled = styled.div`
  display: flex;
  min-height: 130px;
  gap: 15px;
  padding: 13px;
  padding-top: 25px;
  margin-bottom: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .image-column {
    img {
      object-fit: cover;
      max-width: 60px;
      width: auto;
      height: 75px;
    }
    u {
      font-size: 1.35rem;
      margin-top: 40px;
    }
  }
  .description-column {
    p {
      font-size: 1.2rem;
      font-weight: 500;
    }
    p:first-of-type {
      max-height: 47px;
      line-height: 1.3;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    u {
      opacity: 1;
      font-size: 1.35rem;
      @media (max-width: 365px) {
        opacity: 0;
      }
    }
  }
  .price-column {
    margin-left: auto;

    p {
      font-weight: bolder;
      font-size: 1.5rem;
    }
    position: relative;
    .price-button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`;

import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { navLinks } from "../../routes/navLinks";
import { FooterStyles, MenuLink } from "./styles";

export const FooterNav = () => {
  const { pathname } = useLocation();
  const { storeId, harvestId } = useParams();

  const isVisible = !pathname.includes("cart");

  return isVisible ? (
    <FooterStyles>
      {navLinks.map((link, index) => {
        const url = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);
        // const isActive = false;
        const isActive =
          (link.text !== "Shop" && pathname.includes(link.url)) ||
          (pathname.includes("search") && link.url.includes("search")) ||
          (link.text === "Shop" &&
            (pathname.split("/")[3] === "" || !pathname.split("/")[3]));
        let isDisabled = true
        // if (link.text === "Shop" || link.text === "Search") isDisabled=false;

        return (
          <Link key={index} to={`${url}${link.url}`} onClick={e => (isDisabled && e.preventDefault())}>
            <MenuLink isActive={isActive}>
              <img
                src={isActive ? link.iconActive : link.iconDisabled}
                alt="icon"
              />
              <span>{link.text}</span>
            </MenuLink>
          </Link>
        );
      })}
    </FooterStyles>
  ) : (
    <span></span>
  );
};

import React from 'react';

import { CustomLabelStyledDiv, ProductStyled } from './styles';
import { Button } from '../Button';
import { Ratings } from '../Ratings';

import { IProductProps } from './types';

import { Label } from '../Label';
import { useProduct } from './useProduct';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';

export const Product = ({
  product,
  addProductButton,
  optionsButton,
  position,
  urlParams,
  searchEngineResultsPage,
}: IProductProps) => {
  const { handleCart, handleProductModal, translation } = useProduct({
    product,
    position,
  });

  const isBottomBorder = true;

  let promoBanner = <></>;
  if (product.PromoImage){
    promoBanner = (
      <div className="promo">
        <img
          src={`${imgSrcByEnv()}${
            product?.PromoImage && product?.PromoImage.fullpath
          }`}
          alt="promo information"
        />
        {product.PromoText && <p>{product.PromoText}</p>}
      </div>
    );
  } else if (product.PromoText){
    promoBanner = (
      <div className="promo" >
        <p>{product.PromoText}</p>
      </div>
    );
  }

  return (
    product && (
      <ProductStyled
        onClick={handleProductModal}
        isBottomBorder={isBottomBorder}
      >
        <div className="top">
          {product.LabelText ?
            <CustomLabelStyledDiv LabelColor={product.LabelColorCode} LabelTextColor={product.LabelTextColor} LabelPosition={product.LabelPosition}>
              {product.LabelText}
            </CustomLabelStyledDiv>
            : <></>}
          {product.Variants ? (
            <Button
              onClick={() => {}}
              variant="blueGeneric"
              content={optionsButton ? optionsButton : (translation?.getLocalization?.Options || 'OPTIONS')}
            />
          ) : (
            <Button
              onClick={handleCart}
              variant="add"
              url={urlParams}
              content={{
                text: addProductButton ? addProductButton : `+ ${translation?.getLocalization?.Add || 'Add'}`,
                product,
              }}
            />
          )}
          {product.Gallery && product.Gallery[0] ? (
            <picture>
              <img
                src={`${imgSrcByEnv()}${product?.Gallery[0]?.image?.fullpath}`}
                alt="product"
              />
            </picture>
          ) : (
            <img src="http://placehold.jp/150x300.png" alt="product" />
          )}
        </div>
        {product?.Sponsored && <p className="sponsored">{translation?.getLocalization?.Sponsored || 'Sponsored'}</p>}
        <p className="price">
          {product.Price ?
            <>${product.Price.toFixed(2)}<span className='oldPrice'>${product.RegularPrice?.toFixed(2)}</span></>
            :
            <>${product.RegularPrice?.toFixed(2)}</> 
          }
        </p>
        <p className="name">{product.Name}</p>
        {product.RRAmount > 0  &&
          <div className="ratings">
            <Ratings rating={product.RROverall} />
            <span>{product.RRAmount}</span>
          </div>
        }
        <img
          className="save"
          src="../../../files/walmart/walmart-save.png"
          alt="save"
        />
        {searchEngineResultsPage && promoBanner}
        <div className="labels">
          <Label productDetails={true}>{translation?.getLocalization?.Pickup || 'Pickup'}</Label>
          <Label productDetails={true}>{translation?.getLocalization?.TwoDayShipping || '2-day shipping'}</Label>
        </div>
      </ProductStyled>
    )
  );
};

import React from 'react';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';

import { IProduct } from '../../types';
import { useProduct } from '../Product/useProduct';
import { Ratings } from '../Ratings';
import { TermBannerStyled } from './styles';
import { Button } from '../Button';
import { useParams } from 'react-router-dom';
import { BannerVideo } from './styles';

export interface ItermBanner {
  text: string;
  sponsoredBy: string;
  img: string;
  products: IProduct[];
  addProductButton: string;
  optionsButton: string;
  TermBannerVideo?: string;
  TermBannerVideoPosition?: string;
}

export const TermBanner = ({
  text,
  sponsoredBy,
  img,
  products,
  addProductButton,
  optionsButton,
  TermBannerVideo,
  TermBannerVideoPosition,
}: ItermBanner) => {
  const isVideoOnTop = TermBannerVideoPosition === 'top';
  return (<>
    {TermBannerVideo && isVideoOnTop &&
      <BannerVideo isVideoOnTop={isVideoOnTop} className={'promoBannerVideo'}
      src={`${imgSrcByEnv()}${TermBannerVideo}`} controls autoPlay muted loop playsInline />
    }
    <TermBannerStyled>
      <div className="banner">
        <picture>
          <img src={`${imgSrcByEnv()}${img}`} alt={text} />
        </picture>
        <p>{text}</p>
        <p>Sponsored by {sponsoredBy}</p>
      </div>
      <div className="products">
        {products.map((product, index) => (
          <Product product={product} key={index} addProductButton={addProductButton} optionsButton={optionsButton} />
        ))}
      </div>
    </TermBannerStyled>
    {TermBannerVideo && !isVideoOnTop &&
          <BannerVideo isVideoOnTop={isVideoOnTop} className={'promoBannerVideo'} 
          src={`${imgSrcByEnv()}${TermBannerVideo}`} controls autoPlay muted loop playsInline />
    }
  </>);
};

const Product = ({ product, addProductButton, optionsButton }: {
  product: IProduct;
  addProductButton: string;
  optionsButton: string;
}) => {
  const { handleProductModal, handleCart } = useProduct({ product });
  const urlParams = useParams();
  const price = product.Price ?
    <>${product.Price.toFixed(2)}<span className='oldPrice'>${product.RegularPrice?.toFixed(2)}</span></> :
    <>${product.RegularPrice?.toFixed(2)}</>

  return (
    <div className="product" onClick={handleProductModal}>
      {product.Gallery && product.Gallery[0] && (
        <picture>
          <img
            src={`${imgSrcByEnv()}${product?.Gallery[0]?.image?.fullpath}`}
            alt="product"
          />
        </picture>
      )}
      <p className='price'>{price}</p>
      <p>{product.Name}</p>
      <div className="ratings">
        <Ratings rating={product.RROverall} />
        {/* <span>{product.RRAmount}</span> */}
      </div>
      {product.Variants ? (
        <Button
          onClick={() => { }}
          variant="blueGeneric"
          content={optionsButton ? optionsButton : 'OPTIONS'}
        />
      ) : (
        <Button
          onClick={handleCart}
          variant="add"
          url={urlParams}
          content={{
            text: addProductButton ? addProductButton : '+ Add',
            product,
          }}
        />
      )}
    </div>
  );
};

import React from 'react';
import { IProduct } from '../../types';
import parse from "html-react-parser";
import { Code } from 'react-content-loader';
import { ProductsSlider } from '../../components/Modals/variants/ProductOverviewModal/components/SimilarProducts';
import { useBrandRoute } from './useBrandRoute';
import { BrandRouteStyled } from './styles';
import { useLocalization } from '../../hooks/useLocalization';
import { Header, HeaderVariants } from '../../components/Header';

export const BrandRoute = () => {
  const { data, loading } = useBrandRoute();
  const { translation } = useLocalization();

  if (loading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>);
  } else {

    const products = data.getBrandPage?.Products as IProduct[];

    const brandData = data.getBrandPage;

    return (
      <>
        <Header variant={HeaderVariants.search} />
        <BrandRouteStyled>
          <div className="brandPage-container">
            <h1>{data.getBrandPage.Title}</h1>
          </div>

          <React.Fragment key="top">
            <div className='top'>
              {parse(brandData.TopContent)}
            </div>
          </React.Fragment>

          {products && products.length > 1 ?

            <div className="brandProducts">
              <ProductsSlider
                products={products}
                // className="swiper3"
                //title="Popular products"
                title={translation?.getLocalization.BrandProducts}
              ></ProductsSlider>
            </div>

            : ''}


          <React.Fragment key="bottom">
            <div className='bottom'>
              {parse(brandData.BottomContent)}
            </div>
          </React.Fragment>

        </BrandRouteStyled>
      </>
    );
  }
};

import React from 'react';

import { faBox, faCar, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import ImageGallery from 'react-image-gallery';
// import 'react-image-gallery/styles/css/image-gallery.css';

import { Label } from '../../../../../Label';
import { Ratings } from '../../../../../Ratings';

import styled from '@emotion/styled';
import { productOverviewFont, productOverviewPadding } from '../../styles';
import { IProduct } from '../../../../../../types';
import { VariantsSlider } from '../../../../../VariantsSlider';
import { useConfiguration } from '../../../../../../hooks/useConfigurationHook';
import { PdpGallery } from '../PdpGallery';
import { useLocalization } from '../../../../../../hooks/useLocalization';

export const ProductOverview = ({ product, urlParams }: { product: IProduct, urlParams: {storeId:any, harvestId:any} }) => {
  const {
    RegularPrice,
    Price,
    RROverall,
    RRAmount,
    Brand,
    Name,
    Variants,
    LabelColorCode,
    LabelText,
    LabelTextColor
  } = product;

  const [shippingMethodSelected, setShippingMethodSelected] = React.useState({});
  // const [shippingMethodDescription, setShippingMethodDescription] = React.useState({});
  const { ShipmentMethod } = useConfiguration({...urlParams});
  const {translation}=useLocalization();

  // let arr = [""];
  // Gallery && Gallery.map(({ image: { fullpath } }) => {
  //   arr.push(fullpath);
  // });
  // VideoGallery && VideoGallery.map(({ Video: { data, poster }, VideoPosition }) => {
  //   arr.splice( Number(VideoPosition), 0, data.fullpath + '^^^' + poster.fullpath);
  // });
  // arr.shift();

  // const images =
  //   arr && arr?.length
  //     ? arr?.map((elem) => {
  //       let videoPaths = elem.split('^^^');
  //       const video = videoPaths[1]
  //       return ({
  //         original: `${imgSrcByEnv()}${elem.includes("Videos")?video:elem}`,
  //         thumbnail: `${imgSrcByEnv()}${elem.includes("Videos")?video:elem}`,
  //         bulletClass: 'bullet',
  //       })
  //     })
  //     : [
  //       {
  //         original: `http://placehold.jp/150x300.png`,
  //         thumbnail: `http://placehold.jp/150x300.png`,
  //         bulletClass: 'bullet',
  //       },
  //     ];

  const shipmentMethods = React.useMemo(() => {
    return (
      ShipmentMethod?.map((item) => {
        const getIcon = (type: string) => {
          type = type.toLowerCase();

          if (type === 'shipping') {
            return <FontAwesomeIcon icon={faTruck} />;
          }
          if (type === 'pickup') {
            return <FontAwesomeIcon icon={faCar} />;
          }
          if (type === 'delivery') {
            return <FontAwesomeIcon icon={faBox} />;
          }
        };

        const getDesc = (type: string) => {
          type = type.toLowerCase();

          if (type === 'shipping') {
            return translation?.getLocalization?.GetInDays || 'Get it in 2-3 days, Free for Plus, From $5.50 for Club Members';
          }
          if (type === 'pickup') {
            return translation?.getLocalization?.GetIn2Hours || 'Get it in 2 hours, Free for Plus, $4 for Club Members';
          }
          if (type === 'delivery') {
            return translation?.getLocalization?.GetIn3Hours || 'Get it in 3 hours, $8 for Plus, $12 for Club Members';
          }
        };

        return {
          icon: getIcon(item.Name),
          name: item.Name,
          description: getDesc(item.Name),
        };
      }) || []
    );
  }, [ShipmentMethod]);

  React.useEffect(() => {
    setShippingMethodSelected(shipmentMethods?.[0]?.name.toLowerCase());
  }, [shipmentMethods]);

  return (
    <ProductOverviewStyled numberOfBullets={4}>
      <div className="brand">{Brand}</div>
      <div className="name">{Name}</div>
      <div className="price-line">
        <span className="price">
          {product.Price ?
            <>${product.Price.toFixed(2)}<span className='oldPrice'>${product.RegularPrice?.toFixed(2)}</span></>
            :
            <>${product.RegularPrice?.toFixed(2)}</> 
          }
        </span>
        {RRAmount > 0 &&
          <div className="reviews">
            <Ratings rating={RROverall} fontSize="1rem" />
            <span>({RROverall})</span>
            <span>{RRAmount} {translation?.getLocalization?.Reviews || "reviews"}</span>
          </div>
        }
      </div>
      <div className="labels">
        {LabelText ? 
            <CustomLabelStyledDiv LabelColor={LabelColorCode} LabelTextColor={LabelTextColor} >
              {LabelText}
              </CustomLabelStyledDiv>
            : <></>}
        <Label>{translation?.getLocalization?.BestSeller || "Best seller"}</Label>
        <Label>{translation?.getLocalization?.Pick || "pick"}</Label>
      </div>
      <PdpGallery product={product}/>
      <div className="gallery">
        {/* <ImageGallery
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          showNav={false}
          items={images}
          showBullets={true}
        /> */}
      </div>
      {Variants ? (
        <div className="variants">
          <VariantsSlider currentProduct={product} variants={Variants} />
        </div>
      ) : (
        <span></span>
      )}
      {/* <div className="shipping">
        <FontAwesomeIcon icon={faTruck} />
        <span>
          Shipping, <DeliveryDate />
        </span>
        <p>
          <strong>Want it faster?</strong>
          <u>Add an address</u> to see options
        </p>
      </div> */}
      {shipmentMethods.length ? (
        <div className="shipping-methods">
          <p>{translation?.getLocalization?.ShippingMethod || 'Shipping method'}:</p>
          <div className="container">
            {shipmentMethods.map((method, key) => (
              <ShippingMethod
                key={key}
                isActive={shippingMethodSelected === method.name.toLowerCase()}
                method={method}
                onClick={() =>
                  setShippingMethodSelected(method.name.toLowerCase())
                }
              />
            ))}
          </div>
          <div className="description">
            {shipmentMethods.map((method, key) => (
              <div key={key}>
                {shippingMethodSelected === method.name.toLowerCase()
                  ? method.description
                  : ''}
              </div>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="shippedBy">
        <img src="../../../files/walmart/walmart-logo.png" alt="logo" />
        <span>{translation?.getLocalization?.SoldAndShippedBy || 'Sold and shipped by'} Walmart</span>
      </div>
      <div className="returns">
        <img src="../../../files/walmart/walmart-returns.png" alt="logo" />
        <span>{translation?.getLocalization?.Free90DayReturns || 'Free 90-day returns'}</span>
        <u>{translation?.getLocalization?.Details || 'Details'}</u>
      </div>
    </ProductOverviewStyled>
  );
};

const ShippingMethod = ({
  method,
  isActive,
  onClick,
}: {
  method: { name: string; icon: any; description: any };
  isActive?: boolean;
  onClick: () => void;
}) => {
  return (
    <ShippingMethodStyled onClick={onClick} isActive={isActive}>
      {method.icon} {method.name}
    </ShippingMethodStyled>
  );
};

interface Iprops {
  numberOfBullets: number;
}

export const CustomLabelStyledDiv = styled.div<{
  LabelColor?: string;
  LabelTextColor?: string;
}>`
  font-weight: bold;
  padding: 5px;
  border-radius: 3px;
  top: 0;
  background-color: ${({LabelColor}) => LabelColor != '' ? LabelColor : '' };
  color: ${({LabelTextColor}) => LabelTextColor != '' ? LabelTextColor : '' };
`

const ShippingMethodStyled = styled.button<{
  isActive?: boolean;
}>`
  &:hover {
    cursor: pointer;
  }
  height: 40px;
  text-align: left;
  text-decoration: none;
  padding: 10px;
  flex: 1;
  background-color: ${({ isActive }) => (isActive ? '#f4fdfe' : '#ffffff')};
  box-shadow: ${({ isActive, theme }: any) =>
    isActive
      ? `0 0 0 0.125rem ${theme.productAddToCartBg}`
      : '0 0 0 0.0625rem #bbc8d1'};
  border-radius: 5px;
  border: none;

  white-space: nowrap;

  svg {
    color: ${({ theme }: any) => theme.productAddToCartBg};
  }
`;

const ProductOverviewStyled = styled.div<Iprops>`
  padding: ${`0 ${productOverviewPadding} 20px`};
  .brand {
    font-size: ${productOverviewFont};
    margin-top: 7px;
    color: #6e6f70;
  }
  .name {
    margin-top: 18px;
    font-size: 1.9rem;
    font-weight: 500;
    line-height: 1.2;
  }
  .price-line {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .price {
      font-size: 1.8rem;
      font-weight: 500;
      .oldPrice {
        font-size: 15px;
        color: #999999;
        text-decoration: line-through
      }
    }
  }
  
  .shipping-methods {
    margin-top: 15px;
    .container {
      display: flex;
      gap: 10px;
    }
    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 24px;
      text-decoration-line: none;
      margin-bottom: 16px;
    }
    .description {
      margin: 20px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .reviews {
    display: flex;
    align-items: center;
    & > div {
      margin-right: 5px;
    }
    span {
      font-size: 1.4rem;
    }
    span:first-of-type {
      color: #454647;
      margin-right: 5px;
    }
    span:last-of-type {
      text-decoration: underline;
    }
  }
  .labels {
    margin-top: 17px;
    & > div {
      display: inline-block;
      // &:first-of-type {
        margin-right: 10px;
      // }
    }
  }
  .gallery {
    padding: 20px 0;
  }
  .image-gallery-bullets {
    bottom: -15px;
  }
  .bullet {
    border: 1px solid #babbbe !important;
    border-radius: 0px !important;
    width: ${({ numberOfBullets }) => `${170 / numberOfBullets}px`};
    padding: 0px !important;
    margin: 0px !important;
    box-shadow: none !important;
  }
  .active {
    border: 1px solid #000 !important;
  }

  .variants {
    margin-top: 15px;
  }

  .shipping {
    margin-top: 15px;
    svg {
      margin-right: 10px;
    }
    p {
      margin-top: 15px;
      margin-left: 23px;
    }
  }
  .shippedBy,
  .returns {
    margin-top: 25px;
    display: flex;
    align-items: center;

    img {
      width: 14px;
      margin-right: 10px;
    }
    u {
      margin-left: 5px;
    }
  }
`;

import React from "react";

import { useQuery } from "@apollo/client";
import { /*GET_CAROUSEL_ITEMS,*/ GET_HOME_PAGE_BY_ID, GET_PRODUCTS } from "../../queries";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../context";
import { actions } from "../../context/actions";
import { ModalTypes } from "../../components/Modals/types";
// import { useLocalization } from "../../hooks/useLocalization";
import { useConfiguration } from "../../hooks/useConfigurationHook";

export const useMainRoute = () => {
  const { productId,storeId = '', harvestId = ''  } = useParams();
  const { HomePageTemplate } = useConfiguration({ storeId, harvestId });

  const { dispatch } = useAppContext();
  // const { translation } = useLocalization();

  // const { data, loading } = useQuery(GET_PRODUCTS, {
  //   variables: {
  //     first: 50,
  //   },
  // });

  // const { data: carouselData, loading: carouselLoading } =
  //   useQuery(GET_CAROUSEL_ITEMS);

  // const [carouselItems, setCarouselItems] = React.useState([]);
  // const [shuffledProducts, setShuffledproducts] = React.useState({
  //   shuffledCart: [],
  //   shuffledTrending: [],
  // });

  let fullpath;
  HomePageTemplate == undefined || HomePageTemplate?.fullpath == '' ? fullpath = '/Home Pages/_default' : fullpath = HomePageTemplate?.fullpath;

  const { data: homePageStructure, loading: homePageLoading } = useQuery(GET_HOME_PAGE_BY_ID,
    {
      variables: {
        fullpath: fullpath,
      },
    }
  )
  const [homePage, setHomePage] = React.useState();
  React.useEffect(() => {
    if (!homePageLoading) {
      const edges = homePageStructure.getHomePage;
      setHomePage(edges);
    }
  }, [homePageLoading, homePageStructure, harvestId, storeId]);

  // React.useEffect(() => {
  //   if (!loading) {
  //     const products =
  //       !loading &&
  //       data.getEPogListing.edges.map((product: any) => product.node);

  //     const handleShuffling = (items: any) =>
  //       items
  //         .map((value: any) => ({ value, sort: Math.random() }))
  //         .sort((a: any, b: any) => a.sort - b.sort)
  //         .map(({ value }: any) => value);

  //     const shuffledCart = handleShuffling(products).slice(0, 10);
  //     const shuffledTrending = handleShuffling(products).slice(0, 10);

  //     setShuffledproducts({ shuffledCart, shuffledTrending });
  //   }
  // }, [loading, data]);

  // React.useEffect(() => {
  //   if (!carouselLoading) {
  //     const {
  //       getCarouselItemListing: { edges },
  //     } = carouselData || [];

  //     const items = edges.map((item: any) => item.node);
  //     setCarouselItems(items);
  //   }
  // }, [carouselData, carouselLoading]);

  // React.useEffect(() => {
  //   if (productId) {
  //     if (!loading) {
  //       dispatch({
  //         type: actions.TOGGLE_MODAL,
  //         payload: {
  //           isModal: true,
  //           modalType: ModalTypes.productOverview,
  //           modalContent: {
  //             product: { id: productId },
  //           },
  //         },
  //       });
  //     }
  //   }
  // }, [productId, dispatch, loading]);

  return { homePage, /*carouselItems, products: shuffledProducts, translation, */ };
};
